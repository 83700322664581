/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
// import Image from "gatsby-image";
import styled from "styled-components";
import { rhythm } from "../utils/typography";
import { StaticImage } from "gatsby-plugin-image";

const Bio = ({ heading = "", subheading = "" }) => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `);
  const BioContainer = styled.div`
    display: flex;
    margin-bottom: 2.5rem;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      margin-bottom: 1rem;
    }
  `;
  const StyledImage = styled.div`
    margin: 0 2rem 0 0;
    min-width: 50;
    border-radius: 100%;
    flex-shrink: 0;
    > * {
      border-radius: 50%;
    }
    @media (max-width: 768px) {
      display: none !important;
      margin-bottom: 1rem;
    }
  `;
  const BioText = styled.div`
    justify-content: center;
  `;

  const { author } = data.site.siteMetadata;
  let bioHeader;
  let bioSubHeader;
  heading !== "" ? (bioHeader = <h2>{heading}</h2>) : (bioHeader = null);
  subheading !== ""
    ? (bioSubHeader = <h3>{subheading}</h3>)
    : (bioSubHeader = null);

  return (
    <BioContainer>
      <StyledImage>
        <StaticImage
          src="../../content/assets/profile-pic.jpg"
          alt={author}
          placeholder="blurred"
          layout="fixed"
          width={250}
          height={250}
        />
      </StyledImage>
      <BioText>
        {bioHeader}
        {bioSubHeader}
        <p>
          I am web developer based in New York City. I create and improve
          existing websites. Creator and owner of{" "}
          <a className="pink" href="https://hobokenwebsolutions.com">
            Hoboken Web Solutions
          </a>
          .
        </p>
        <p>
          I have experience with JavaScript, Gatsby, SFCC, E-Commerce Google
          Analytics, Google Tag Manager, AWS.
        </p>
        <p>
          Any questions? Feel free to reach out at{" "}
          <a className="green" href="mailto:hello@chrisshimmin.com">
            hello@chrisshimmin.com
          </a>
        </p>
      </BioText>
    </BioContainer>
  );
};

export default Bio;
